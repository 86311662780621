import { mapGetters } from "vuex";
var aws = require("aws-sdk");

var rscBucketName = "pepa.resources";

function config() {
    var stdtBucketRegion = "eu-west-2";
    var stdtIdentityPoolId = "eu-west-2:1a013c1c-3c01-4a47-ad5b-e75ff13dc0eb";

    AWS.config.update({
        region: stdtBucketRegion,
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: stdtIdentityPoolId
        })
    });

    var s3 = new AWS.S3({ apiVersion: "latest" });
    return s3;
}

const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_COMPLETE = 3;
const STATUS_FAILED = 4;

function encode(data)
{
    var str = data.reduce(function(a,b){ return a+String.fromCharCode(b) },'');
    return btoa(str).replace(/.{76}(?=.)/g,'$&\n');
}

const s3 = config();

export const fileResourceMixin = {
    computed: {
        ...mapGetters({ currentUser: "currentUser" }),
        isInitial() {
            return this.currentStatus === STATUS_INITIAL;
          },
          isSaving() {
            return this.currentStatus === STATUS_SAVING;
          },
          isSuccess() {
            return this.currentStatus === STATUS_SUCCESS;
          },
          isComplete() {
            return this.currentStatus === STATUS_COMPLETE;
          },
          isFailed() {
            return this.currentStatus === STATUS_FAILED;
          },
    },
    data() {
        return {
            completedPercentage: 0,
            currentStatus: null,
            remoteName: ''
        }
    },
    mounted() {
        this.currentStatus = STATUS_INITIAL;
      },
    methods: {
        async getDocument(remoteName) {
        
           
                const filePath = "documents/" + remoteName.trim();
                var params = {
                  Bucket: rscBucketName,
                  Key: filePath
              }; 


              return new Promise((resolve, reject) => {
                try {      
                    s3.getSignedUrl('getObject', params, (err, url) => {
                     if (err) reject(err)
                     else resolve(url)
                    })  
          
                } catch(e) {
                  reject(e.message)
                }
           
              })

        },
        async getLessonDocument(remoteName) {

                const filePath = "teaching/" + remoteName.trim();
                var params = {
                  Bucket: rscBucketName,
                  Key: filePath
              }; 
        
              return new Promise((resolve, reject) => {
                try {      
                    s3.getSignedUrl('getObject', params, (err, url) => {
                     if (err) reject(err)
                     else resolve(url)
                    })  
          
                } catch(e) {
                  reject(e.message)
                }
           
              })

        },
        async getDiagram(remoteName) {


                const filePath = "diagrams/" + remoteName.trim();
                var params = {
                  Bucket: rscBucketName,
                  Key: filePath
              }; 

              return new Promise((resolve, reject) => {
                try {      
                    s3.getSignedUrl('getObject', params, (err, url) => {
                     if (err) reject(err)
                     else resolve(url)
                    })  
          
                } catch(e) {
                  reject(e.message)
                }
           
              })

        },
        // async uploadDocument(bucketFolder, formData) {
        //     this.currentStatus = STATUS_SAVING;
        //     var vm = this

        //     const files = formData.getAll('file');

        //     var file = files[0];

        //     var path = "U" + this.wordNumericValue(this.currentUser.schoolID);

        //     var ext =
        //         Math.random()
        //             .toString(36)
        //             .substring(2, 9) +
        //         "." +
        //         file.name.split(".").pop();
        //     var fileName = file.name.replace(/\.[^/.]+$/, "_") + ext;

        //     this.remoteName = path + "/" + fileName.toLowerCase();

        //     const filePath = this.remoteName.trim();

        //     var params = {
        //         Bucket: rscBucketName,
        //         Key: filePath,
        //         ContentType: file.type,
        //         Body: file,
        //         ACL: "private",
        //         ServerSideEncryption: "AES256"
        //     };

        //     var s3 = config();

        //     s3.upload(params)
        //         .on('httpUploadProgress', function (evt) {
        //             vm.completedPercentage = Math.round(evt.loaded / evt.total * 100);
        //             //console.log(`File uploaded: ${vm.completedPercentage}%`);
        //         }).send(function (err, data) {
        //             if (err) {
        //                 // an error occurred, handle the error
        //                 console.log(err, err.stack);
        //                 vm.currentStatus = STATUS_FAILED;
        //                 return;
        //             }

        //             vm.currentStatus = STATUS_COMPLETE;
        //         })
        // },

        // // private functions
        // wordNumericValue(n) {
        //     var alpha = "abcdefghijklmnopqrstuvwxyz".split("");
        //     var digits = Array.from(n.toString()).map(Number);

        //     var value = digits
        //         .map(v => {
        //             if (v >= 25) return "";
        //             return alpha[v];
        //         })
        //         .join("");

        //     var name = "pep" + value;
        //     return name.toUpperCase();
        // },
        // refreshUpload() {
        //     this.currentStatus = STATUS_INITIAL;
        //     this.file = ''
        //     this.remoteName = ''
        // },
        completed() {
            this.currentStatus = STATUS_COMPLETE
        }

    },
}